import React from "react";
import {Box, styled} from "@mui/material";

const DottedSeparator = () => {
    return (
        <Box
            sx={{
                width: '70px',
                height: '10px',
                background: 'white',
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
            }}>
            <Dot />
            <Dot />
            <Dot />
        </Box>
    )
}

const Dot = styled(Box)({
    background: '#b2b2b2',
    width: '10px',
    height: '10px'
})

export default DottedSeparator