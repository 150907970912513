import Section from "../../../components/molecules/Section";
import React, {Fragment} from "react";
import {Box, Button, styled, Typography} from "@mui/material";
import PlanTitleCapsule from "../../../components/molecules/PlanTitleCapsule";
import PlanPriceBox from "../../../components/molecules/PlanPriceBox";
import PlanItem from "../../../components/molecules/PlanItem";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../../store";
import theme from "../../../theme";

const Section5: React.FC = () => {
    const dispatch = useDispatch<Dispatch>()
    const handleClick = () => dispatch.auth.setOpen(true)

    return (
        <Section
            sx={{
                backgroundImage: 'linear-gradient(to bottom, #2567ad, #204d84)',
                paddingTop: '121px',
                paddingBottom: '128px'
            }}
            innerSx={{
                display: 'grid',
                gridTemplateRows: 'repeat(3, auto)',
                justifyContent: 'center',
                gap: '0px'
            }}>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: 'repeat(2, auto)',
                    gap: '0px',
                    justifyContent: 'center'
                }}>
                <Typography
                    variant={'xl2'}
                    fontWeight={'normal'}
                    color={'text.primary'}
                    sx={{marginBottom: '0px'}}>
                    PLAN
                </Typography>
                <Typography
                    variant={'md1'}
                    fontWeight={'500px'}
                    color={'text.primary'}
                    sx={{marginBottom: '149px', justifySelf: 'center'}}>
                    料金プラン
                </Typography>
            </Box>
            <Box
                sx={{
                    marginBottom: '110px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <Typography
                    variant={'md1'}
                    fontWeight={'bold'}
                    color={'text.primary'}
                    sx={{
                        marginBottom: '29px',
                        [theme.breakpoints.down('sm')]: {
                            padding: '0 16px'
                        }
                    }}>
                    予算に応じたカスタマイズも可能です。まずはお気軽にお問合せください。
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '53px',
                        [theme.breakpoints.down(1366)]: {
                            flexDirection: 'column',
                            '& > .MuiBox-root': {
                                justifyItems: 'center',
                                '& .price-box': {
                                    marginRight: 0
                                }
                            }
                        },
                        [theme.breakpoints.down('sm')]: {
                            '& > .MuiBox-root': {
                                width: '350px',
                                '& > .MuiBox-root:nth-child(1) .MuiTypography-root': {
                                    fontSize: '40px'
                                },
                                '& > .MuiBox-root:nth-child(2)': {
                                    alignItems: 'center'
                                },
                                '& .price-box .MuiTypography-xl2': {
                                    fontSize: '35px'
                                }
                            }
                        }
                    }}>
                    <Plan>
                        <PlanTitleCapsule planName={'BASIC'} backgroundColor={'#7c99b9'} shadowColor={'0 3px 0 0 #002855'} />
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '482px',
                                background: 'white',
                                boxShadow: '0 3px 0 0 #5c5c5c',
                                borderRadius: '55px',
                                zIndex: 1,
                                marginTop: '12px',
                                alignItems: 'center',
                                gap: '19px',
                                justifyContent: 'center'
                            }}>
                            <PlanItem
                                title={'対応SNS'}
                                description={
                                    <Fragment>
                                        Instagram（投稿 12回 / 月）<br />
                                        Twitter（120ツイート / 月）
                                    </Fragment>
                                } />
                            <PlanItem
                                title={'撮影'}
                                description={
                                    <Fragment>
                                        Max2回 / 月
                                    </Fragment>
                                } />
                            <PlanItem
                                title={'各種 SNS 投稿業務'}
                                description={
                                    <Fragment>
                                        企画・編集・投稿
                                    </Fragment>
                                } />
                            <PlanItem
                                title={'基本ツール分析報告'}
                                description={
                                    <Fragment/>
                                } />
                            <PlanItem
                                title={'ユーザー対応'}
                                description={
                                    <Fragment>
                                        DM・リプライなど
                                    </Fragment>
                                } />

                        </Box>
                        <PlanPriceBox price={'40万円'} backgroundColor={'#7c99b9'} shadowColor={'0 3px 0 0 #002855'} />
                    </Plan>
                    <Plan>
                        <PlanTitleCapsule planName={'BUSINESS'} backgroundColor={'#5492d5'} shadowColor={'0 3px 0 0 #002a57'} />
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '482px',
                                background: 'white',
                                boxShadow: '0 3px 0 0 #5c5c5c',
                                borderRadius: '55px',
                                zIndex: 1,
                                marginTop: '12px',
                                alignItems: 'center',
                                gap: '19px'
                            }}>
                            <Typography
                                sx={{paddingTop: '109px', fontSize: '90px', marginBottom: '29px'}}
                                fontWeight={'normal'}
                                color={'text.secondary'} >
                                BASIC+
                            </Typography>
                            <PlanItem
                                title={'Youtube 投稿'}
                                description={
                                    <Fragment>
                                        企画・撮影・サムネ制作　 8 本 / 月
                                    </Fragment>} />
                            <PlanItem
                                title={'撮影'}
                                description={
                                    <Fragment>
                                        Max4回 / 月
                                    </Fragment>
                                } />
                        </Box>
                        <PlanPriceBox price={'80万円'} backgroundColor={'#5492d5'} shadowColor={'0 3px 0 0 #002a57'} />
                    </Plan>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: 'repeat(2, auto)',
                    gap: '22px',
                    justifyContent: 'center'
                }}>
                <Typography
                    variant={'base'}
                    fontWeight={'normal'}
                    color={'text.primary'}
                    sx={{textAlign: 'center'}}>
                    まずはお気軽にお問い合わせください。<br />
                    お見積もりのご依頼も承ります。
                </Typography>
                <Button variant={'contained'} size={'large'} onClick={handleClick}>
                    資料無料ダウンロード
                </Button>
            </Box>
        </Section>
    )
}

const Plan = styled(Box) ({
    display: 'grid',
    gridTemplateRows: 'repeat(3, auto)',
    width: '560px',
    justifyItems: 'end',
    gap: '0px'
})

export default Section5