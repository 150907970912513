import {createTheme} from "@mui/material";
import React from "react";
import themeOptions from "./theme.options";

declare module '@mui/material/styles' {
    interface Theme {
    }

    interface Palette {
    }
    interface PaletteOptions {
    }

    interface TypographyOptions {
        xs: React.CSSProperties
        sm: React.CSSProperties
        base: React.CSSProperties
        md1: React.CSSProperties
        md2: React.CSSProperties
        lg1: React.CSSProperties
        lg2: React.CSSProperties
        lg3: React.CSSProperties
        xl1: React.CSSProperties
        xl2: React.CSSProperties
        xxl1: React.CSSProperties
        xxl2: React.CSSProperties
    }

    interface TypographyVariants {
        xs: React.CSSProperties
        sm: React.CSSProperties
        base: React.CSSProperties
        md1: React.CSSProperties
        md2: React.CSSProperties
        lg1: React.CSSProperties
        lg2: React.CSSProperties
        lg3: React.CSSProperties
        xl1: React.CSSProperties
        xl2: React.CSSProperties
        xxl1: React.CSSProperties
        xxl2: React.CSSProperties
    }

    interface TypographyVariantsOptions {
        xs?: React.CSSProperties
        sm?: React.CSSProperties
        base?: React.CSSProperties
        md1?: React.CSSProperties
        md2?: React.CSSProperties
        lg1?: React.CSSProperties
        lg2?: React.CSSProperties
        lg3?: React.CSSProperties
        xl1?: React.CSSProperties
        xl2?: React.CSSProperties
        xxl1?: React.CSSProperties
        xxl2?: React.CSSProperties
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        xs: true;
        sm: true,
        base: true,
        md1: true,
        md2: true,
        lg1: true,
        lg2: true,
        lg3: true,
        xl1: true,
        xl2: true,
        xxl1: true,
        xxl2: true
    }
}

const theme = createTheme(themeOptions)

export default theme