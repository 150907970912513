import {Button, Dialog, DialogContent, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import Navbar from "../../../components/organisms/Navbar";
import Image from "../../../components/atoms/Image";
import top from '../../../images/top.svg'
import {Download} from "@mui/icons-material";
import ContactInput from "./ContactInput";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../../../store";
import theme from "../../../theme";

const DownloadPopup = () => {
    const open = useSelector((root: RootState) => root.auth.open)
    const dispatch = useDispatch<Dispatch>()
    const handleClose = () => {
        dispatch.auth.setOpen(false)
    }

    const [inquiry, setInquiry] = useState<any>({
        name: '',
        company: '',
        email: '',
        phone: ''
    })

    const contactProps = (key: string) => ({
        value: inquiry[key],
        onChange: (value: string) => setInquiry({
            ...inquiry,
            [key]: value
        })
    })

    const handleDownload = () => {
        let link = document.createElement('a')
        link.download = 'Social One.pdf'
        link.href = '/Social_ONE.pdf'

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    // useEffect(() => {
    //     if (open === false) {
    //         setInquiry({
    //             name: '',
    //             company: '',
    //             email: '',
    //             phone: ''
    //         })
    //     }
    // }, [open])

    return (
        <Dialog open={open} fullScreen={true} onClose={handleClose}>
            <Navbar title={'資料ダウンロード'} />
            <DialogContent sx={{
                padding: 0,
                background: 'linear-gradient(to bottom, #2567ad, #204d84)',
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '123px',
                paddingBottom: '24px',
                [theme.breakpoints.down('lg')]: {
                    flexDirection: 'column'
                },
                [theme.breakpoints.down('sm')]: {
                    justifyContent: 'flex-start',
                    gap: '80px',
                    '& > div:first-child': {
                        marginTop: '32px',
                        '& > div': {
                            padding: '32px 8px'
                        }
                    }
                }
            }}>
                <Stack direction={'column'} alignItems={'center'} gap={'24px'} marginTop={'147px'}>
                    <Typography color={'text.primary'} fontWeight={'bold'} variant={'md2'}>
                        Social ONE紹介資料
                    </Typography>
                    <Stack direction={'column'} gap={'24px'} sx={{
                        padding: '60px 80px',
                        background: '#ffffff',
                        border: 'solid 1px #707070'
                    }}>
                        <Typography fontWeight={'normal'} variant={'base'} color={'text.secondary'} marginBottom={'19px'}>
                            ・Social ONEがどんなサービスなのかを知りたい<br/>
                            ・実際に導入した企業の事例を知りたい<br/>
                            ・社内での検討に使用したい
                        </Typography>
                        <Stack direction={'row'} gap={'14px'} alignItems={'center'}>
                            <Download sx={{
                                '& path': {
                                    fill: '#292f6f'
                                },
                                fontSize: '40px'
                            }}/>
                            <Typography fontSize={'40px'} fontWeight={'bold'} color={'#292f6f'} noWrap>
                                無料ダウンロード
                            </Typography>
                        </Stack>
                        <Button variant={'contained'} size={'medium'} sx={{
                            width: '243px',
                            boxShadow: '0 3px 0 0 #000b4a',
                            background: '#2465a9',
                            color: 'white',
                            '&:hover': {
                                boxShadow: '0 3px 0 0 #000b4a',
                                background: '#2465a9',
                                color: 'white',
                            },
                            alignSelf: 'center'
                        }} onClick={handleDownload}>
                            ダウンロードする
                        </Button>
                    </Stack>
                </Stack>
                <Stack direction={'column'} alignItems={'center'} gap={'24px'} marginTop={'75'}>
                    <Typography color={'text.primary'} fontWeight={'normal'} variant={'base'}>
                        下記フォームに必要事項を入力し、送信後に<br/>
                        「ダウンロードする」ボタンを押してください。
                    </Typography>
                    <Stack direction={'column'} gap={'18px'} padding={'54px'} sx={{
                        background: 'linear-gradient(to right, #2567ad 0%, #204d84 100%)',
                        border: 'solid 1px #fff',
                        [theme.breakpoints.down('sm')]: {
                            paddingRight: '24px',
                            paddingLeft: '24px'
                        }
                    }}>
                        <ContactInput label={'お名前'} {...contactProps('name')} variant={'2'} />
                        <ContactInput label={'メールアドレス'} {...contactProps('email')} variant={'2'} />
                        <ContactInput label={'会社名'} {...contactProps('company')} variant={'2'} />
                        <ContactInput label={'お電話番号'} {...contactProps('phone')} variant={'2'} />
                        <Button variant={'contained'} size={'medium'} sx={{
                            alignSelf: 'center',
                            width: '243px',
                            marginTop: '67.6px'
                        }}>
                            送信する
                        </Button>
                    </Stack>
                </Stack>

                <Image src={top} sx={{
                    position: 'absolute',
                    left: '36px',
                    bottom: '116.3px',
                    cursor: 'pointer'
                }} onClick={handleClose}/>
            </DialogContent>
        </Dialog>
    )
}

export default DownloadPopup