import React from "react";
import {Box, Typography, useMediaQuery} from "@mui/material";
import Image from "../atoms/Image";
import theme from "../../theme";

type FeatureProps = {
    id: string,
    title: string,
    description: React.ReactNode,
    image: any
}

const Feature: React.FC<FeatureProps> = (props) => {
    const isDownMD = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <Box
            sx={{
                width: '100%',
                display: 'grid',
                gridTemplateRows: 'repeat(2, auto)',
                gap: '20px',
                padding: '0px'
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '33.5px',
                    padding: '0px',
                    alignItems: 'end'
                }}>
                <Typography
                    variant={isDownMD ? 'xxl1' : 'xxl2'}
                    fontWeight={'bold'}
                    lineHeight={'80px'}
                    color={'text.primary'}>
                    {props.id}
                </Typography>
                <Typography
                    variant={isDownMD ? 'md2' : 'lg1'}
                    fontWeight={'bold'}
                    color={'text.primary'}>
                    {props.title}
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: 'inherit',
                height: '570px',
                borderRadius: '64px',
                background: 'white',
                alignItems: 'center',
                gap: '48px',
                padding: '16px',
                boxSizing: 'border-box',
                [theme.breakpoints.down('md')]: {
                    gap: '24px'
                }
            }}>
                <Typography
                    noWrap
                    variant={'md1'}
                    fontWeight={'500px'}
                    color={'primary.main'}
                    sx={{width: '294px', marginTop: '82px'}}>
                    {props.description}
                </Typography>
                <Image sx={{
                    // width: '240px',
                    // height: '240px',
                    marginBottom: '25px'
                }} src={props.image}/>
            </Box>
        </Box>
    )
}

export default Feature