import React, {Fragment} from "react";
import Section from "../../../components/molecules/Section";
import {Box, Button, Typography} from "@mui/material";
import Feature from "../../../components/molecules/Feature";
import feature1 from '../../../images/feature/feature1.png'
import feature2 from '../../../images/feature/feature2.png'
import feature3 from '../../../images/feature/feature3.png'
import feature4 from '../../../images/feature/feature4.png'
import {useDispatch} from "react-redux";
import {Dispatch} from "../../../store";
import theme from "../../../theme";

const Section3: React.FC = () => {
    const dispatch = useDispatch<Dispatch>()
    const handleClick = () => dispatch.auth.setOpen(true)

    return (
        <Section
            id={'features'}
            sx={{
                backgroundImage: 'linear-gradient(to bottom, #2567ad, #204d84)',
                paddingTop: '106px',
                paddingBottom: '132px'
            }}
            innerSx={{
                display: 'grid',
                girdTemplateRows: 'repeat(3, auto)',
                justifyContent: 'center',
                gap: '0px'
            }}>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: 'repeat(2, auto)',
                    gap: '0px',
                    justifyContent: 'center'
                }}>
                <Typography
                    variant={'xl2'}
                    fontWeight={'normal'}
                    color={'text.primary'}
                    sx={{marginBottom: '0px'}}>
                    FEATURE
                </Typography>
                <Typography
                    variant={'md1'}
                    fontWeight={'normal'}
                    color={'text.primary'}
                    sx={{marginBottom: '137px', justifySelf: 'center'}}>
                    サービスの特徴
                </Typography>
            </Box>
            <Box
                sx={{
                    marginBottom: '114px',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 449px)',
                    gap: '80px',
                    justifyContent: 'center',
                    [theme.breakpoints.down('md')]: {
                        gridTemplateColumns: 'repeat(2, 320px)',
                        gap: '40px'
                    },
                    [theme.breakpoints.down('sm')]: {
                        gridTemplateRows: 'repeat(4, auto)',
                        gridTemplateColumns: '320px'
                    }
                }}>
                <Feature
                    id={'01'}
                    title={'十分なフォローアップ'}
                    description={
                        <Fragment>
                            定例の打ち合わせに加えて<br />
                            LINEなどのコミュニケーション<br />
                            ツールで常時ご質問を受け付け<br />ております。
                        </Fragment>
                    }
                    image={feature1}/>
                <Feature
                    id={'02'}
                    title={'業界の制限なし'}
                    description={
                        <Fragment>
                            BtoC企業様だけでなく、建築業<br />
                            や製造業といったBtoB企業様<br />
                            また学校などの教育機関の<br />
                            皆様のご支援が可能です。
                        </Fragment>
                    }
                    image={feature2}/>
                <Feature
                    id={'03'}
                    title={'豊富な支援体制'}
                    description={
                        <Fragment>
                            当社が人材紹介業で培ってきた<br />
                            採用広報のノウハウを活用し、<br />
                            WebサイトやPR映像制作も含め、<br />
                            SNS採用・広報に関する貴社の<br />
                            課題解決をご支援します。
                        </Fragment>
                    }
                    image={feature3}/>
                <Feature
                    id={'04'}
                    title={'内製化支援'}
                    description={
                        <Fragment>
                            通常SNS運用は継続が不可欠な<br />
                            ため、
                            長期的に代行費用を<br />
                            投じる必要があります。<br />
                            しかし、当社のSNS支援では<br />
                            自走できる運用チームの<br />
                            育成プランもございます。
                        </Fragment>
                    }
                    image={feature4}/>
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: 'repeat(2, auto)',
                    gap: '22px',
                    justifyContent: 'center'
                }}>
                <Typography
                    variant={'base'}
                    fontWeight={'normal'}
                    color={'text.primary'}
                    sx={{textAlign: 'center'}}>
                    まずはお気軽にお問い合わせください。<br />
                    お見積もりのご依頼も承ります。
                </Typography>
                <Button variant={'contained'} size={'large'} onClick={handleClick}>
                    資料無料ダウンロード
                </Button>
            </Box>
        </Section>
    )
}

export default Section3