import {createModel} from "@rematch/core";
import {RootModel} from "./index";

export type AuthStateType = {
    isAuthenticated: boolean
    token: string | null,
    user: {
        id: number,
        username: string
    } | null,
    open: boolean
}

const authInitialState: AuthStateType = {
    isAuthenticated: false,
    token: null,
    user: null,
    open: false
}

export const auth = createModel<RootModel>()({
    name: 'auth',
    state: authInitialState,
    reducers: {
        setOpen(state, open: boolean) {
            state.open = open
        }
    },
    effects: (dispatch) => ({

    })
})