import React, {Fragment} from "react";
import Section from "../../../components/molecules/Section";
import Image from "../../../components/atoms/Image";
import introductionBg from '../../../images/introduction-bg.png'
import {Box, Button, Typography, useMediaQuery} from "@mui/material";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../../store";
import theme from "../../../theme";

const Introduction: React.FC = () => {
    const dispatch = useDispatch<Dispatch>()
    const handleClick = () => dispatch.auth.setOpen(true)
    const isDownSM = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Section extra={
            <Fragment>
                <Image src={introductionBg} sx={{
                    zIndex: 0,
                    width: '100vw',
                    height: 'inherit',
                    objectFit: 'cover',
                    position: 'absolute',
                    top: 0,
                    left: 0
                }}/>
                <Box sx={{
                    zIndex: 1,
                    width: '100vw',
                    height: 'inherit',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: .48,
                    background: '#bbf1fc'
                }}/>
            </Fragment>
        } innerSx={{
            zIndex: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
        }} sx={{
            padding: '157px 96px',
            height: '793px',
            justifyContent: 'start',
            alignContent: 'start',
            [theme.breakpoints.down('sm')]: {
                padding: '60px 16px',
                height: '420px'
            }
        }} id={'intro'}>
            <Typography variant={isDownSM ? 'lg3' : 'xxl2'} fontWeight={'medium'} color={'text.primary'}>
                採用も広報も<br/>
                SNSで解決
            </Typography>
            <Typography typography={isDownSM ? 'base' : 'md1'} fontWeight={'bold'} color={'text.primary'}>
                たくさんの人に届くSNS運用をサポート。<br />
                SNS採用・広報の企画から運用代行まですべておまかせ。
            </Typography>
            <Button variant={'contained'} size={isDownSM ? 'medium' : 'large'} sx={{
                marginTop: '58px'
            }} onClick={handleClick}>
                資料無料ダウンロード
            </Button>
        </Section>
    )
}

export default Introduction