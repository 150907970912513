import Section from "../../../components/molecules/Section";
import CaseStudyItem from '../components/CaseStudy'
import {Stack, Typography} from "@mui/material";
import {Fragment} from "react";
import enos from '../../../images/enos.png'
import meikiko from '../../../images/logo_meikiko.gif'
import theme from "../../../theme";

const CaseStudy = () => {
    return (
        <Section sx={{
            padding: '138px 0 212px 0'
        }} innerSx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '92px'
        }} id={'case-study'}>
            <Typography color={'text.secondary'} variant={'base'} fontSize={'40px'} fontWeight={'medium'}>
                導入事例
            </Typography>
            <Stack direction={'row'} gap={'20px'} sx={{
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column'
                }
            }}>
                <CaseStudyItem title={'ENOSイノベーションパートナーズ様'} content={
                    <Fragment>
                        Facebookの運用代行を依頼しました。<br/>
                        弊社でPRしたい内容をしっかりと理解した上で投稿内容を作成いただけるため、確認工数がかからず非常に助かりました。<br/>
                        また、原稿作成だけではなく写真・動画撮影及び編集も対応いただけるため、投稿内容の幅を広げることができました。
                    </Fragment>
                } image={enos} />

                <CaseStudyItem title={'株式会社メイキコウ様'} content={
                    <Fragment>
                        ワクワクする提案と、安心できるフォロー体制！<br/>
                        ワクワクするようなご提案を頂き、一緒にお仕事をさせていただくことになりました。<br/>
                        困ったことや、小さな不安点など丁寧に対応していただいており、安心して楽しくお仕事ができています！
                    </Fragment>
                } image={meikiko}/>
            </Stack>
        </Section>
    )
}

export default CaseStudy