import React from "react";
import {InputBase, Stack, Typography} from "@mui/material";
import theme from "../../../theme";

type ContactInputProps = {
    label: string,
    value: string,
    onChange: (value: string) => any,
    variant?: '1' | '2'
}

const ContactInput: React.FC<ContactInputProps> = ({variant = '1', ...props}) => {
    return (
        <Stack
            gap={variant === '2' ? '4px' : '0px'}
            direction={variant === '2' ? 'column' : 'row'} justifyContent={'space-between'} alignItems={variant === '2' ? 'flex-start' : 'center'}>
            <Typography variant={'base'} color={variant === '2' ? 'text.primary' : 'text.secondary'} fontWeight={'normal'} noWrap>
                {props.label}
            </Typography>
            <Stack direction={'row'} alignItems={'center'} gap={'41px'} position={'relative'}>
                <Typography variant={'base'} fontWeight={'normal'} color={'error'} sx={{
                    padding: '4px 20px',
                    border: `1px solid ${theme.palette.error.main}`,
                    ...(variant === '2' && {
                        position: 'absolute',
                        padding: 0,
                        border: 'none',
                        zIndex: 1,
                        top: '7px',
                        left: '18px',
                        opacity: '.46'
                    })
                }} noWrap>
                    必須
                </Typography>
                <InputBase sx={{
                    background: '#f7f7f7',
                    border: '1px solid #d9d9d9',
                    width: variant === '2' ? '333px' : '367px',
                    color: 'black',
                    padding: variant === '2' ? '0 8px 0 62px' : '0 8px',
                    height: '42px',
                    boxSizing: 'border-box',
                    [theme.breakpoints.down('sm')]: {
                        width: variant === '2' ? '333px' : '120px',
                    }
                }} value={props.value} onChange={e => props.onChange(e.target.value)}/>
            </Stack>
        </Stack>
    )
}

export default ContactInput