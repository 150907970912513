import React from "react";
import {Box, Typography} from "@mui/material";

const StepBox: React.FC<{ stepNo: string }> = ({stepNo}) => {
    return (
        <Box
            sx={{
                width: '176px',
                height: '176px',
                backgroundImage: 'linear-gradient(to bottom, #2567ad, #204d84)',
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <Typography
                variant={'lg3'}
                lineHeight={'38px'}
                fontWeight={'normal'}
                color={'text.primary'}>
                Step
            </Typography>
            <Typography
                variant={'xxl2'}
                lineHeight={'80px'}
                fontWeight={'bold'}
                color={'text.primary'}>
                {stepNo}
            </Typography>
        </Box>
    )
}

export default StepBox