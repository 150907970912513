import {ThemeOptions} from "@mui/material";

const themeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: '#292f6f'
        },
        text: {
            primary: '#ffffff',
            secondary: '#292f6f',
            disabled: '#707070'
        },
        error: {
            main: '#f55050'
        },
    },
    typography: {
        fontFamily: [
            'Noto San JP',
            'sans-serif'
        ].join(','),
        fontWeightLight: 300,
        fontWeightBold: 700,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        xs: {
            fontSize: '9px'
        },
        sm: {
            fontSize: '15px',
        },
        base: {
            fontSize: '18px',
        },
        md1: {
            fontSize: '20px'
        },
        md2: {
            fontSize: '26px'
        },
        lg1: {
            fontSize: '30px'
        },
        lg2: {
            fontSize: '35px'
        },
        lg3: {
            fontSize: '38px'
        },
        xl1: {
            fontSize: '50px'
        },
        xl2: {
            fontSize: '65px'
        },
        xxl1: {
            fontSize: '70px'
        },
        xxl2: {
            fontSize: '80px'
        }
        //    9, 15, 18, 20, 26, 30, 35, 38, 50, 65, 70, 80
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    cursor: 'pointer'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    whiteSpace: 'nowrap'
                },
                contained: {
                    fontWeight: 700,
                    boxShadow: '0 3px 0 0 #dd8282',
                    background: '#ffff91',
                    color: '#292f6f',
                    '&:hover': {
                        background: '#ffff91',
                        boxShadow: '0 3px 0 0 #dd8282',
                        opacity: .8
                    }
                },
                containedSizeMedium: {
                    borderRadius: '44.5px',
                    fontSize: '26px',
                    padding: '4px 37px',
                },
                containedSizeLarge: {
                    borderRadius: '44px',
                    fontSize: '30px',
                    padding: '20px 53px'
                }
            }
        }
    }
}

export default themeOptions