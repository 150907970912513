import Section from "../../../components/molecules/Section";
import {Box, Button, Stack, Typography} from "@mui/material";
import ContactInput from "../components/ContactInput";
import {useState} from "react";
import theme from "../../../theme";

const Contact = () => {
    const [inquiry, setInquiry] = useState<any>({
        name: '',
        company: '',
        email: '',
        content: ''
    })

    const contactProps = (key: string) => ({
        value: inquiry[key],
        onChange: (value: string) => setInquiry({
            ...inquiry,
            [key]: value
        })
    })

    return (
        <Section sx={{
            background: 'linear-gradient(to bottom, #2567ad, #204d84)',
            padding: '115px 0 78px 0'
        }} innerSx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }} id={'contact'}>
            <Typography variant={'xl2'} color={'text.primary'} fontWeight={'medium'}>
                CONTACT
            </Typography>
            <Typography variant={'md1'} color={'text.primary'} fontWeight={'medium'}>
                お問合わせ
            </Typography>
            <Typography color={'text.primary'} fontWeight={'bold'} marginBottom={'43px'} marginTop={'52px'} variant={'md2'}>
                まずはお気軽にご相談ください
            </Typography>
            <Stack direction={'column'} alignItems={'stretch'} gap={'36px'} padding={'85px 94px'} sx={{
                backgroundColor: '#fff',
                width: 'min(60vw, 863px)',
                boxSizing: 'border-box',
                [theme.breakpoints.down('sm')]: {
                    width: '100vw',
                    padding: '64px 16px'
                }
            }}>
                <ContactInput label={'お名前'} {...contactProps('name')} />
                <ContactInput label={'会社名'} {...contactProps('company')} />
                <ContactInput label={'メールアドレス'} {...contactProps('email')} />
                <ContactInput label={'お問合せ内容'} {...contactProps('content')} />
                <Button variant={'contained'} size={'medium'} sx={{
                    width: '200px',
                    alignSelf: 'center',
                    marginTop: '12px'
                }}>
                    送信する
                </Button>
            </Stack>
            <Typography variant={'md1'} color={'text.primary'} fontWeight={'normal'} marginTop={'48px'} sx={{
                [theme.breakpoints.down('sm')]: {
                    padding: '0 16px',
                    fontSize: '16px'
                }
            }}>
                担当より3営業日以内に回答させていただきます。
            </Typography>
        </Section>
    )
}



export default Contact