import React from "react";
import {Box, Typography} from "@mui/material";

type StepDetailsProps = {
    title: string,
    description: React.ReactNode
}

const StepDetails: React.FC<StepDetailsProps> = (props) => {
    return (
        <Box
            sx={{
                width: '358px',
                display: 'flex',
                flexDirection: 'column',
                gap: '22px'
            }}>
            <Typography
                variant={'lg2'}
                fontWeight={'bold'}
                textAlign={'center'}
                color={'primary.main'}>
                {props.title}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    background: '#f8f8f8',
                    height: '343px',
                    justifyContent: 'center',
                    borderRadius: '29px',
                }}>
                <Typography
                    noWrap
                    variant={'base'}
                    fontWeight={'normal'}
                    color={'primary.main'}
                    sx={{width: '288px', paddingTop: '51px'}}>
                    {props.description}
                </Typography>
            </Box>
        </Box>
    )
}

export default StepDetails