import Section from "../../../components/molecules/Section";
import {Typography} from "@mui/material";

const Copyright = () => {
    return (
        <Section sx={{
            padding: '16px 0'
        }}>
            <Typography fontWeight={'regular'} variant={'sm'} color={'text.disabled'}>
                Copyright © 2022 株式会社One Terrace
            </Typography>
        </Section>
    )
}

export default Copyright