import React, {Fragment} from "react";
import Section from "../../../components/molecules/Section";
import {Box, Typography} from "@mui/material";
import StepBox from "../../../components/molecules/StepBox";
import DottedSeparator from "../../../components/molecules/DottedSeparator";
import StepDetails from "../../../components/molecules/StepDetails";

const Section4: React.FC = () => {
    return (
        <Section
            sx={{
                background: 'white',
                paddingTop: '62px',
                paddingBottom: '164px'
            }}
            innerSx={{
                display: 'grid',
                gridTemplateRows: 'repeat(2, auto)',
                justifyContent: 'center',
                gap: '0px'
            }}>
            <Box
                sx={{
                    margin: '0px',
                    width: '100vw',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '365px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#eaeaea'
                }}>
                <Typography
                    variant={'xl2'}
                    fontWeight={'normal'}
                    color={'text.secondary'}
                    lineHeight={'65px'}>
                    SUPPORT
                </Typography>
                <Typography
                    variant={'md1'}
                    fontWeight={'normal'}
                    color={'text.secondary'}>
                    支援内容
                </Typography>
            </Box>
            <Box
                sx={{
                    paddingTop: '135px',
                    display: 'grid',
                    gridTemplateRows: 'repeat(2, auto)',
                    justifyContent: 'center',
                    gap: '47px'
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '73px',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <StepBox stepNo={'01'} />
                    <DottedSeparator />
                    <StepBox stepNo={'02'} />
                    <DottedSeparator />
                    <StepBox stepNo={'03'} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirectoin: 'row',
                        gap: '26px'
                    }}>
                    <StepDetails
                        title={'ヒアリング'}
                        description={
                            <Fragment>
                                PR したい商品やサービスについて<br />
                                詳細なヒアリングを行います。<br />
                                ブランディング・企画・<br />
                                コンセプト化を行い、目指すべき姿<br />の擦り合わせをします。
                                進行する SNS 採用・広報の<br />
                                プロセスごとに適切な PR 方法を<br />ご提案します。
                            </Fragment>
                        } />
                    <StepDetails
                        title={'企画立案・運用'}
                        description={
                            <Fragment>
                                各媒体の中で最も反応が得られる
                                SNS 媒体を選出し、運用します。<br />
                                撮影や編集内容については、<br />
                                広報や各部署のご担当者様、<br />
                                代表の方にご確認いただきながら<br />最適な解決策をご提案します。
                            </Fragment>
                        } />
                    <StepDetails
                        title={'PR を楽しむ'}
                        description={
                            <Fragment>
                                運用と同時進行で PDCA を回し、<br />
                                改善しながら進めていきます。<br />
                                弊社スタッフと一緒に SNS 運用・<br />
                                PR 活動を楽しみながら行っていた<br />
                                だきます。<br />
                                まずは、SNS を通して認知度を<br />
                                高めるためのきっかけ作りから<br />
                                始めて行きましょう。
                            </Fragment>
                        } />
                </Box>
            </Box>
        </Section>
    )
}

export default Section4