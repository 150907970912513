import React from "react";
import {Stack, SxProps, Theme, Typography} from "@mui/material";
import Image from "../../../components/atoms/Image";

type OtherServiceProps = {
    image?: any,
    text: React.ReactNode,
    sx?: SxProps<Theme>,
    link: string
}
const OtherService: React.FC<OtherServiceProps> = ({ image, text, sx, link }) => {
    return (
        <Stack direction={'column'} alignItems={'stretch'} sx={{
            background: '#ffffff',
            textDecoration: 'none',
            ...sx
        }} component={'a'} href={link}>
            <Stack sx={{
                height: '123px',
                placeContent: 'center',
                display: 'grid'
            }}>
                <Image src={image} sx={{
                    width: '176px',
                    height: '81px',
                    objectFit: 'cover'
                }} />
            </Stack>
            <Typography variant={'xs'} fontWeight={'medium'} color={'text.secondary'} sx={{
                background: 'rgba(232, 243, 255, 0.51)',
                padding: '17px 19px 0px 19px',
                height: '75px',
                boxSizing: 'border-box'
            }}>
                {text}
            </Typography>
        </Stack>
    )
}

export default OtherService