import React from "react";
import {Box, Typography} from "@mui/material";

const PlanTitleCapsule: React.FC<{planName: string, backgroundColor: string, shadowColor: string}> = ({planName, backgroundColor, shadowColor}) => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '154px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                background: backgroundColor,
                boxShadow: shadowColor,
                borderRadius: '88px'
            }}>
            <Typography
                variant={'xl2'}
                fontWeight={'normal'}
                color={'text.primary'}>
                {planName}
            </Typography>
        </Box>
    )
}

export default PlanTitleCapsule