import React from "react";
import {Stack, Typography} from "@mui/material";
import Image from "../../../components/atoms/Image";

type CaseStudyProps = {
    image?: any,
    title: string,
    content: React.ReactNode
}
const CaseStudy: React.FC<CaseStudyProps> = props => {
    return (
        <Stack direction={'column'} alignItems={'center'} gap={'16px'} maxWidth={'350px'}>
            <Image src={props.image} sx={{
                width: '202px',
                height: '202px',
                objectFit: 'contain',
                border: 'solid 1px #707070',
                borderRadius: '100%'
            }}/>
            <Typography variant={'md2'} fontWeight={'medium'} marginTop={'13px'} alignSelf={'flex-start'}>
                {props.title}
            </Typography>
            <Typography variant={'md1'} fontWeight={'normal'} alignSelf={'flex-start'}>
                {props.content}
            </Typography>
        </Stack>
    )
}

export default CaseStudy