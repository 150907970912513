import { init, RematchDispatch, RematchRootState } from "@rematch/core"
import { models, RootModel } from "./models"
import immerPlugin from "@rematch/immer";
// import persistPlugin from "@rematch/persist";
// import storage from 'redux-persist/lib/storage'

export const store = init<RootModel>({
    models,
    plugins: [
        immerPlugin(),
        // persistPlugin({
        //     key: 'root',
        //     storage,
        //     whitelist: ['auth']
        // })
    ]
})

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel>