import React from "react";
import Section from "../../../components/molecules/Section";
import Image from "../../../components/atoms/Image";
import section2 from '../../../images/section-2.svg'
import {Typography} from "@mui/material";
import theme from "../../../theme";

const Section2: React.FC = () => {
    return (
        <Section innerSx={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '70px',
            gap: '80px',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                '& .MuiTypography-root': {
                    fontSize: '24px'
                }
            }
        }}>
            <Typography variant={'base'} fontSize={'40px'} fontWeight={'bold'} color={'text.secondary'}>
                こんな不安を感じていませんか？
            </Typography>
            <Image sx={{
                width: 'min(85vw, 100%)'
            }} src={section2}/>
        </Section>
    )
}

export default Section2