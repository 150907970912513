import Section from "../../../components/molecules/Section";
import {Button, Typography, useMediaQuery} from "@mui/material";
import Image from "../../../components/atoms/Image";
import idea from '../../../images/idea.png'
import {useDispatch} from "react-redux";
import {Dispatch} from "../../../store";
import theme from "../../../theme";

const SNS = () => {
    const dispatch = useDispatch<Dispatch>()
    const handleClick = () => dispatch.auth.setOpen(true)
    const isDownSM = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Section sx={{
            background: 'white',
            paddingTop: '265px',
            paddingBottom: '138px',
            [theme.breakpoints.down('sm')]: {
                padding: '100px 0 80px',
                '& img': {
                    display: 'none'
                },
                '& .MuiTypography-xxl1': {
                    fontSize: '50px'
                }
            }
        }} innerSx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '24px',
            [theme.breakpoints.down('sm')]: {
                padding: '0 16px',
                '& img': {
                    display: 'none'
                },
                '& .MuiTypography-xxl1': {
                    fontSize: '50px'
                }
            }
        }}>
            <Typography variant={'xxl1'} color={'text.secondary'}>
                採用も広報も<br/>
                SNSで解決
            </Typography>
            <Button size={isDownSM ? 'medium' : 'large'} variant={'contained'} sx={{
                marginBottom: '11px'
            }} onClick={handleClick}>
                資料無料ダウンロード
            </Button>
            <Typography variant={'sm'} color={'text.secondary'} textAlign={'center'} sx={{
                position: 'relative'
            }}>
                まずはお気軽にお問い合わせください。<br/>
                お見積もりのご依頼も承ります。

                <Image sx={{
                    width: '268px',
                    height: '260px',
                    position: 'absolute',
                    top: '-50px',
                    right: '-218px'
                }} src={idea}/>
            </Typography>
        </Section>
    )
}

export default SNS