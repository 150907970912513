import React from "react";
import Introduction from "./sections/Introduction";
import Copyright from "./sections/Copyright";
import Section1 from "./sections/Section1";
import Section3 from "./sections/Section3";
import Section2 from "./sections/Section2";
import Section4 from "./sections/Section4";
import Section5 from "./sections/Section5";
import OtherServices from "./sections/OtherServices";
import SNS from "./sections/SNS";
import Contact from "./sections/Contact";
import CaseStudy from "./sections/CaseStudy";
import {useMediaQuery} from "@mui/material";
import theme from "../../theme";

const Home: React.FC<any> = () => {
    const isDownLG = useMediaQuery(theme.breakpoints.down('lg'))
    return (
        <>
            <Introduction />
            <Section1 />
            <Section2 />
            <Section3 />
            { !isDownLG && <Section4 /> }
            <Section5 />
            <CaseStudy />
            <Contact />
            <SNS />
            <OtherServices />
            <Copyright />
        </>
    )
}

export default Home