import React from 'react';
import {Routes, Route } from 'react-router-dom'
import Home from "./pages/home/Home";
import {Stack} from "@mui/material";
import Navbar from "./components/organisms/Navbar";
import DownloadPopup from "./pages/home/components/DownloadPopup";

function App() {

    return (
        <Stack direction={'column'} justifyContent={'flex-start'} alignItems={'stretch'}>
            <Navbar />
            <DownloadPopup />
            <Routes>
                <Route path={'/'} element={ <Home /> }/>
            </Routes>
        </Stack>
    );
}

export default App;
