import React, {useState} from "react";
import {Button, Link, Stack, Typography, useMediaQuery} from "@mui/material";
import Image from "../atoms/Image";
import logo from '../../images/logo.svg'
import theme from "../../theme";
import DownloadPopup from "../../pages/home/components/DownloadPopup";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../store";

const Navbar: React.FC<{
    title?: string
}> = (props) => {
    const dispatch = useDispatch<Dispatch>()
    const downLG = useMediaQuery(theme.breakpoints.down('lg'))
    const downSM = useMediaQuery(theme.breakpoints.down('sm'))

    const follow = (e: any) => {
        e.preventDefault()

        document.querySelector(e.target.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        })

        window.history.pushState({}, '', e.target.getAttribute('href'))
    }

    return (
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{
            position: 'sticky',
            top: 0,
            height: '91px',
            background: '#fff',
            padding: '0px 35px',
            borderBottom: '1px solid rgb(0, 0, 0, .1)',
            zIndex: 10
        }}>
            <Stack onClick={() => {
                if (props.title) return

                document.querySelector('#intro')?.scrollIntoView({
                    behavior: 'smooth'
                })

                window.history.pushState({}, '', '#intro')
            }}>
                <Image src={logo} sx={{
                    width: '243px',
                    height: '62px',
                    cursor: 'pointer'
                }} />
            </Stack>
            { (props.title && !downSM) && <Typography noWrap fontWeight={'bold'} fontSize={'40px'} color={'text.secondary'}>{props.title}</Typography>}
            { (props.title && !downLG) && <Stack width={'243px'} />}

            { !props.title &&
            <Stack direction={'row'} gap={downLG ? '32px' : '55px'} alignItems={'center'} sx={theme => ({
                [theme.breakpoints.down('lg')]: {
                    '& .MuiTypography-root': {
                        fontSize: '18px'
                    }
                },
                [theme.breakpoints.down('md')]: {
                    '& .MuiButton-root': {
                        display: 'none'
                    }
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            })}>
                <Stack direction={'row'} gap={downLG ? '24px' : '93px'} alignItems={'center'}>
                    <Link color={'text.secondary'} variant={'md2'} fontWeight={'medium'} whiteSpace={'nowrap'} href={'#features'} onClick={follow}>
                        サービス
                    </Link>
                    <Link color={'text.secondary'} variant={'md2'} fontWeight={'medium'} whiteSpace={'nowrap'} href={'#case-study'} onClick={follow}>
                        導入事例
                    </Link>
                    <Link color={'text.secondary'} variant={'md2'} fontWeight={'medium'} whiteSpace={'nowrap'} href={'https://oneterrace.jp'} target={'_blank'}>
                        会社情報
                    </Link>
                </Stack>
                <Stack direction={'row'} gap={'16px'} alignItems={'center'}>
                    <Button variant={'contained'} size={'medium'} href={'#contact'} onClick={follow}>
                        お問合せ
                    </Button>
                    <Button variant={'contained'} size={'medium'} onClick={() => dispatch.auth.setOpen(true)}>
                        資料請求
                    </Button>
                </Stack>
            </Stack> }
        </Stack>
    )
}

export default Navbar