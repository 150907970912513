import React from "react";
import Section from "../../../components/molecules/Section";
import {Box, Stack, Typography} from "@mui/material";
import Image from "../../../components/atoms/Image";
import socialOne1 from '../../../images/social-one-1.png'
import socialOne2 from '../../../images/social-one-2.png'
import theme from "../../../theme";

const Section1: React.FC = () => {
    return (
        <Section
            sx={{
                backgroundImage: 'linear-gradient(to bottom, #2567ad, #204d84)',
                height: '850px',
                [theme.breakpoints.down('sm')]: {
                    height: '525px'
                }
            }}
            innerSx={{
                display: 'grid',
                gridTemplateRows: 'repeat(3, auto)',
                justifyContent: 'center',
                justifyItems: 'center'
            }}>
            <Typography
                variant={'xl1'}
                fontWeight={'bold'}
                color={'text.primary'}
                sx={{marginBottom: '28px', justifySelf: 'center'}}>
                Social ONEとは
            </Typography>
            <Typography
                sx={theme => ({
                    marginBottom: '91px',
                    [theme.breakpoints.down('md')]: {
                        fontSize: '20px'
                    },
                    [theme.breakpoints.down('sm')]: {
                        padding: '0 16px'
                    }
                })}
                variant={'md2'}
                fontWeight={'bold'}
                color={'text.primary'} >
                お客様と定期的なコミュニケーションを取りながら、企画提案、撮影＆取材、<br />
                投稿、分析、報告を行うことで、お客様のSNS運用を支援します。
            </Typography>
            <Stack sx={theme => ({
                display: 'grid',
                gridTemplateColumns: 'repeat(2, min-content)',
                gap: '168px',
                padding: '0px',
                [theme.breakpoints.down('sm')]: {
                    gap: '48px'
                }
            })}>
                <Stack sx={{
                    width: 'min(35vw, 340px)',
                    height: 'auto',
                    aspectRatio: '1/1',
                    borderRadius: '100%', overflow: 'hidden', background: 'white'
                }} justifyContent={'center'} alignItems={'center'}>
                    <Image sx={{
                        width: '100%',
                        height: '100%',
                    }} src={socialOne1}/>
                </Stack>
                <Stack sx={{
                    width: 'min(35vw, 340px)',
                    height: 'auto',
                    aspectRatio: '1/1',
                    borderRadius: '100%', overflow: 'hidden', background: 'white'
                }} justifyContent={'center'} alignItems={'center'}>
                    <Image sx={{
                        width: '100%',
                        height: '100%',
                    }} src={socialOne2}/>
                </Stack>
            </Stack>

        </Section>
    )
}

export default Section1