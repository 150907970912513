import React from "react";
import {Box, Typography} from "@mui/material";
import {Check} from "@mui/icons-material";
import theme from "../../theme";

type PlanItemProps = {
    title: string,
    description: React.ReactNode
}

const PlanItem: React.FC<PlanItemProps> = (props) => {
    return (
        <Box
            sx={{
                width: '371px',
                display: 'flex',
                flexDirection: 'row',
                gap: '35px',
                [theme.breakpoints.down('sm')]: {
                    gap: '16px',
                    margin: '0 16px',
                    width: 'calc(100% - 32px)'
                }
            }}>
            <Check sx={{
                fontSize: '30px',
                '& path': {
                    fill: '#7c99b9'
                }
            }}/>
            {/*<Box sx={{width: '30px', height: '22.3px', background: 'lightblue'}}/>*/}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2px'
                }}>
                <Typography variant={'md2'} fontWeight={'bold'} lineHeight={'22.3px'} color={'text.secondary'} >{props.title}</Typography>
                <Typography variant={'sm'} fontWeight={'normal'} color={'text.secondary'} sx={{marginLeft: '26px'}}>{props.description}</Typography>
            </Box>
        </Box>
    )
}

export default PlanItem