import Section from "../../../components/molecules/Section";
import OtherService from "../components/OtherService";
import {Fragment} from "react";
import {Stack, Typography} from "@mui/material";

import service1 from '../../../images/other-services/service1.svg'
import service2 from '../../../images/other-services/service2.svg'
import service3 from '../../../images/other-services/service3.svg'
import service4 from '../../../images/other-services/service4.svg'
import theme from "../../../theme";

const OtherServices = () => {
    return (
        <Section sx={{
            background: 'linear-gradient(to bottom, #2567ad, #204d84)',
            paddingTop: '50px',
            paddingBottom: '54px'
        }} innerSx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '30px'
        }}>
            <Typography variant={'md1'} color={'text.primary'} fontWeight={'medium'}>
                その他サービスのご紹介
            </Typography>
            <Stack direction={'row'} gap={'26px'} sx={{
                [theme.breakpoints.down('md')]: {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, auto)'
                },
                [theme.breakpoints.down('sm')]: {
                    gap: '8px',
                    placeContent: 'center',
                    placeItems: 'center',
                    '& > a': {
                        transform: 'scale(.95)'
                    }
                }
            }}>
                <OtherService text={<Fragment>
                    韓国現地からのIT・製造業・建設業向け<br />
                    新卒トップ層/優秀な若手人材紹介
                </Fragment>} image={service1} link={'https://mono-kei.com'}/>
                <OtherService text={<Fragment>
                    ベトナム現地からの製造業・建設業界向け<br />
                    優秀エンジニア紹介
                </Fragment>} image={service2} link={'https://chokutori.com'}/>
                <OtherService text={<Fragment>
                    チョクトリ紹介動画。企業様の<br />
                    インタビューや外国人の日本語能力など<br />
                    をご覧いただけます
                </Fragment>} image={service3} link={'https://kr.chokutori.com'}/>

                <OtherService sx={{
                    marginLeft: '60px',
                    [theme.breakpoints.down('md')]: {
                        marginLeft: 0
                    }
                }} text={<Fragment>
                    次世代のものづくりに邁進する人々の<br />
                    ストーリーをお届け。<br />
                    製造業の経営者を応援するウェブメディア。
                </Fragment>} image={service4} link={'https://www.youtube.com/channel/UC0DTaVD31kQe-fdg8JmLU0A/videos'}/>
            </Stack>
        </Section>
    )
}

export default OtherServices