import React from "react";
import {Box, Typography} from "@mui/material";

const PlanPriceBox: React.FC<{price: string, backgroundColor: string, shadowColor: string}> = ({price, backgroundColor, shadowColor}) => {
    return (
        <Box
            sx={{
                width: '80%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                padding: '53px 0px 33px 0',
                background: backgroundColor,
                boxShadow: shadowColor,
                borderRadius: '34px',
                borderTopRightRadius: '0px',
                borderTopLeftRadius: '0px',
                marginRight: '16px',
                marginTop: '-20px',
                zIndex: 0
            }} className={'price-box'}>
            <Typography
                variant={'xl2'}
                fontWeight={'normal'}
                color={'text.primary'}> ¥
                <Typography variant={'xl2'} fontWeight={'normal'} color={'#ffff91'}>
                    {price}
                </Typography>
                <Typography variant={'xl2'} fontWeight={'normal'} color={'text.primary'}>/</Typography>
                <Typography variant={'lg1'} fontWeight={'normal'} color={'text.primary'} sx={{alignSelf: 'end'}}>月</Typography>
            </Typography>
        </Box>
    )
}

export default PlanPriceBox