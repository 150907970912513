import React from "react";
import {Stack, SxProps, Theme} from "@mui/material";

type SectionProps = {
    sx?: SxProps<Theme>,
    innerSx?: SxProps<Theme>,
    children: React.ReactNode,
    extra?: React.ReactNode,
    id?: string
}

const Section: React.FC<SectionProps> = ({ sx, innerSx, children, ...props }) => {
    return (
        <Stack {...props} display={'grid'} sx={{
            position: 'relative',
            placeItems: 'center',
            placeContent: 'center',
            boxSizing: 'border-box',
            ...sx
        }} id={props.id}>
            <Stack sx={{
                boxSizing: 'border-box',
                ...innerSx
            }}>
                { children }
            </Stack>
            { props.extra && props.extra }
        </Stack>
    )
}

export default Section